import React, { useState } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Link from '@material-ui/core/Link'
import NoSsr from '@material-ui/core/NoSsr'

const registerPageUsestyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
        overflow: 'auto',
        maxHeight: '60vh',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: 800,
    },
    drawer: {
        maxWidth: '100%',
        maxHeight: '25%',
        flexShrink: 0,
    },
    drawerPaper: {
        height: '25%',
    },
    fullWidthTxt: {
        width: '60vw',
    },
    button: {
        marginTop: '16px'
    },
    card: {
        minWidth: 275,
    },
    cardMargin: {
        marginTop: '1rem'
    },
    link: {
        margin: theme.spacing(1),
    },
    outputBox: {
        wordBreak: 'break-all'
    },
}), { classNamePrefix: 'rp', name: 'rp' });

const RegisterPage = ({ location }) => {
    const classes = registerPageUsestyles()
    const [loginFormDisable, setloginFormDisable] = useState(false)
    const [values, setValues] = useState({ email: "", password: "" })
    const [exchangeCode, setExchangeCode] = useState("")
    const [loginOutput, setLoginOutput] = useState({})
    const [tokenOutPut, setTokenOutput] = useState({})

    const onChange = ({ target }) => {
        setValues({ ...values, [target.name]: target.value });
    }

    const onTxtExchangeCodeChange = ({ target }) => {
        setExchangeCode(target.value);
    }

    function _handleLogin(evt) {
        console.log(evt)
        evt.preventDefault()
        setloginFormDisable(true)
        if (values.email === "" || values.password === "") {
            alert(`Missing Parameters`)
            setloginFormDisable(false)
            return false
        }
        let proxy_endpoint = `https://iestbc4ws2.execute-api.us-west-2.amazonaws.com/Stage`
        // let proxy_endpoint = `http://127.0.0.1:3000`
        let params = new URLSearchParams(location.search)
        let app_name = params.get('app_name') || "arwgame"
        // let redirect_uri = params.get('redirect_uri') || "https://acidrainworld.com/en/"
        axios({
            method: 'POST',
            url: `${process.env.MEMBERSHIP_PROXY_ENDPOINT}/users`,
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                "app_name": app_name
            },
            data: {
                "data": {
                    "username": values.email,
                    "password": values.password
                }
            }
        })
        .then((res) => {
            setloginFormDisable(false)
            if (res.status === 201 && res.hasOwnProperty('data') ) {
                // Login success
                // window.location.href = `${res.data.body.redirect_uri}?e=${res.data.body.e}`
                // pass exchange code to redirect uri
                console.log(res.data)
                setLoginOutput(res.data)
            } else {
                console.log(`login fail`)
            }
        })
        .catch((err) => {
            setloginFormDisable(false)
            console.log(`Error`)
        })
    }

    function _handleExchangeCode(evt) {
        console.log(evt)
        evt.preventDefault()
        if (exchangeCode === "") {
            alert(`Missing Parameters`)
            return false
        }
        let proxy_endpoint = `https://iestbc4ws2.execute-api.us-west-2.amazonaws.com/Stage`
        // let proxy_endpoint = `http://127.0.0.1:3000`
        let params = new URLSearchParams(location.search)
        let app_name = params.get('app_name') || "arwgame"
        let redirect_uri = "http://localhost:8888" || params.get('redirect_uri') || "https://acidrainworld.com/en/"
        axios({
            method: 'POST',
            url: `${process.env.MEMBERSHIP_PROXY_ENDPOINT}/authorize`,
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                "app_name": app_name,
                "redirect_uri": redirect_uri
            },
            data: {
                "data": {
                    "exchange_code": exchangeCode
                }
            }
        })
        .then((res) => {
            if (res.status === 200 && res.hasOwnProperty('data') ) {
                console.log(res.data)
                setTokenOutput(res.data)
            } else {
                console.log(`exchange code fail`)
            }
        })
        .catch((err) => {
            console.log(`Error`)
        })
    }

    return (
        <NoSsr>
            <div className={classes.root}>
                <Paper className={classes.container} elevation={0}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <h1>Aither Member Registration</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <form noValidate autoComplete="on">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email / Username"
                                    className={classes.textField}
                                    margin="normal"
                                    type="email"
                                    variant="outlined"
                                    disabled={loginFormDisable}
                                    onChange={onChange}
                                    autoComplete="section-aithermember username"
                                    required
                                    fullWidth
                                    />
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    className={classes.textField}
                                    margin="normal"
                                    type="password"
                                    variant="outlined"
                                    disabled={loginFormDisable}
                                    onChange={onChange}
                                    autoComplete="section-aithermember new-password"
                                    required
                                    fullWidth
                                    />
                                <label htmlFor="contained-button-submit">
                                    <Button variant="contained" disabled={loginFormDisable} onClick={_handleLogin}>
                                        Create
                                    </Button>
                                </label>
                                <Link href={`/auth/?app_name=arwgame&redirect_uri=http://localhost:8888`} className={classes.link}>
                                    You already have an account? Let's login.
                                </Link>

                            </form>
                            <Card className={classNames(classes.card, classes.cardMargin)}>
                                <CardContent>
                                    <CardHeader
                                        title="HTTP Response"
                                        subheader="Content-Type: application/json"
                                        />
                                    <Typography variant="body2" color="textSecondary" component="p" className={classes.outputBox}>
                                        {JSON.stringify(loginOutput)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
                {process.env.GATSBY_ACTIVE_ENV == 'development'
                    ?
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, classes.container),
                        }}
                        anchor="bottom">
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    id="exchange_code"
                                    label="Paste the exchange code here"
                                    placeholder="exchange code"
                                    className={classNames(classes.textField, classes.fullWidthTxt)}
                                    margin="normal"
                                    onChange={onTxtExchangeCodeChange}
                                    required
                                    />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={_handleExchangeCode}
                                    >
                                    Get Access Token
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Card className={classNames(classes.card, classes.cardMargin)}>
                                    <CardContent>
                                        <CardHeader
                                            title="HTTP Response"
                                            subheader="Content-Type: application/json"
                                            />
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.outputBox}>
                                            {JSON.stringify(tokenOutPut)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Drawer>
                    :
                    <></>
                }
            </div>
        </NoSsr>
    )
}

export default RegisterPage
